import logo from './logo.svg';
import './App.scss';
import './AirIntro.scss';
import './Mobile.scss';
import { useEffect, useState } from 'react';
import Back from './Back';

import CookieConsent from "react-cookie-consent";


function App() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);

  const [signInError, setSignInError] = useState(null);

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isOverCta, setIsOverCta] = useState(false);

  const [messageClasses, setMessageClasses] = useState([]);

  useEffect(() => {

    Back.recordEvent("Landed");
    setTimeout(() => {
      Back.recordEvent("LandedFor1");
    }, 1000);
    setTimeout(() => {
      Back.recordEvent("LandedFor2");
    }, 2000);
    setTimeout(() => {
      Back.recordEvent("LandedFor3");
    }, 3000);
    setTimeout(() => {
      Back.recordEvent("LandedFor4");
    }, 4000);
    setTimeout(() => {
      Back.recordEvent("LandedFor5");
    }, 5000);

    const timeToShowTexts = 100;
    const timeToShowChat = 550;
    const timeAfterChat = 1200;

    let checkpoint = 1100;
    let delay1 = 500;

    setTimeout(() => {
      setMessageClasses((prev) => {
        prev.push("Message1");
        return [...prev];
      })
    }, checkpoint + 1000);

    setTimeout(() => {
      setMessageClasses((prev) => {
        prev.push("Message2");
        return [...prev];
      })
    }, checkpoint + 1000 + delay1 + timeToShowChat + timeAfterChat);

    setTimeout(() => {
      setMessageClasses((prev) => {
        prev.push("Message3");
        return [...prev];
      })
    }, checkpoint + 1000 + delay1 + timeToShowChat + timeAfterChat + timeToShowChat + timeAfterChat);

    setTimeout(() => {
      setMessageClasses((prev) => {
        prev.push("Message4");
        return [...prev];
      })
    }, checkpoint + 1000 + delay1 + 1000 + timeToShowChat + timeAfterChat + timeToShowChat + timeAfterChat + timeToShowChat + timeAfterChat);


    setTimeout(() => {
      setMessageClasses((prev) => {
        //prev.push("FadeAll");
        return [...prev];
      })
    }, checkpoint + 1000 + delay1 + 3600 + timeToShowChat + timeAfterChat + timeToShowChat + timeAfterChat + timeToShowChat + timeAfterChat);


  }, []);

  const handleCtaEnter = () => {
    setIsOverCta(true);
  };
  const handleCtaLeave = () => {
    setIsOverCta(false);
  };

  const handleCtaClick = () => {
    Back.recordEvent("ClickedCTA");
    setTimeout(() => {
      Back.recordEvent("ClickedCTAWaited1");
    }, 1000);
    setTimeout(() => {
      Back.recordEvent("ClickedCTAWaited2");
    }, 2000);
    setTimeout(() => {
      Back.recordEvent("ClickedCTAWaited3");
    }, 3000);
    setTimeout(() => {
      Back.recordEvent("ClickedCTAWaited4");
    }, 4000);
    setTimeout(() => {
      Back.recordEvent("ClickedCTAWaited5");
    }, 5000);

    setIsPageLoading(true);
    manageDots();

    window.gtag("event", "generate_lead", {
      currency: "USD",
      value: 4
    });
    window.rdt('track', 'Lead', {
      "currency": "USD",
      "value": 4
    });
    window.pintrk('track', 'lead', {
      event_id: generateUniqueId(),
      value: 4,
      currency: 'USD'
    });

    setTimeout(() => {
      redirectWithQueryString("https://www.airhug.app", {
        is_lander: true,
        show_intro: true
      });
    }, 250);
  };

  let introClassName = "AirIntro " + messageClasses.join(" ");

  let mainClassName = "MainLayout";
  if (isOverCta) {
    mainClassName += " IsOverCta";
  }

  return (
    <div className={mainClassName}>

      {isPageLoading && (<div className='PageLoadingOverlay'></div>)}

      <div className='MainSheet'>
        <div className='SheetContent'>
          <div className="Logo">

            <img src="air-logo-space.png" className="logo-img" />
          </div>
          <div className="Headline">
            <div className="tagline">Discover Something New, About You.</div>
            <div className='subtag'>Try a 3-minute, AI-guided exercise. No typing required.</div>
          </div>
          <div className='MobileCTA'>
            <div className='mobile-notice'>
              Air does not support smartphones just yet. Please visit <b>www.airhug.app</b> on your
              laptop or computer to try Air.
            </div>
            <div className='CTA'>
              Try Air
            </div>
            <div className='terms-and-conditions'>
              By signing up, you agree to our
              <a href="https://www.airhug.app/terms">Terms of Use</a>
              and acknowledge the
              <a href="https://www.airhug.app/privacy">Privacy Policy</a>
              .
            </div>
          </div>
          <div className='DesktopCTA CTA gtm-cta gtm-cta-main' id="mainStartCta" onClick={handleCtaClick} onMouseEnter={handleCtaEnter} onMouseLeave={handleCtaLeave}>
            Try Air
          </div>
          <div className='terms-and-conditions'>
            By continuing and using Air, you agree to our <a href="https://www.airhug.app/terms">Terms of Use</a> and<br />acknowledge our <a href="https://www.airhug.app/privacy">Privacy Policy</a>.
          </div>
          {/* <div className='below-cta'>
            Web-based. No account or download required.<br />

          </div> */}
          <div className='below-cta-terms'>
            Air is free to use 3 times per week. For unlimited usage, Air Premium is available for $14.99/mo.
          </div>

          <div className='disclaimer-cta'>

            <p>
              <a href="https://www.airhug.app/cookies/index.html" className='legal-link'>Cookie Policy</a> &bull; <a href="https://www.airhug.app/privacy" className='legal-link'>Privacy Policy</a> &bull; <a href="https://www.airhug.app/terms" className='legal-link'>Terms of Use</a>
            </p>
          </div>
        </div>
      </div>

      <div className={introClassName}>
        <div className='ai-face-content'>
          <div className='ai-face'>
            <img src="face-top-light.png" className='face-img face-top-img' />
            <img src="face-bottom-light.png" className='face-img face-bottom-img' />
          </div>
        </div>

        <div className='air-messages'>
          <div className='air-message air-message-hi Message1'>{isPageLoading ? (<span>Great! Getting ready<span id="loadingDots">...</span></span>) : "I'm Air, your AI."}</div>
          <div className='air-message Message2'>I'm <span className='value-subject'>trained</span> with one purpose &mdash;</div>
          <div className='air-message Message3'>to help you <span className='value-prop'>learn about yourself</span> quickly.</div>
          <div className='air-message air-message-end Message4'>Ready to start?</div>
          <img src="about-arrow.png" className='arrow-img' />
        </div>
      </div>

      {/* <CookieConsent
        location="bottom"
        buttonText="Accept All"
        cookieName="cookies-accept"
        style={{ color: "#666", background: "#fff", borderTop: '2px solid #eee' }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px", padding: "10px 16px", borderRadius: "4px", marginRight: "40px" }}
        expires={150}
      >
        <span style={{ padding: "0 0 0 40px" }}> We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic.</span>

      </CookieConsent> */}
    </div>
  );
}

export default App;

function redirectWithQueryString(newBaseUrl, newQueryParams) {
  // Get the current URL and its query string
  const currentUrl = window.location.href;
  const queryString = window.location.search;

  // Create a URLSearchParams object for easy manipulation
  const urlParams = new URLSearchParams(queryString);

  // Add/update query parameters from the newQueryParams object
  for (const key in newQueryParams) {
    urlParams.set(key, newQueryParams[key]);
  }

  // Construct the new URL
  let newUrl = newBaseUrl;
  if (urlParams.toString()) {
    newUrl += '?' + urlParams.toString();
  }

  // Redirect the user
  window.location.href = newUrl;
}

function manageDots() {
  let numDots = 3;
  function updateDots() {
    const myDiv = document.getElementById('loadingDots'); // Get a reference to your div
    console.log('myDiv', myDiv);
    numDots = numDots === 3 ? 1 : numDots + 1;
    let dots = "";
    for (let i = 0; i < numDots; i++) {
      dots += ".";
    }
    if (!myDiv) {
      return;
    }
    myDiv.textContent = dots;
  }

  // Call the updateDots function repeatedly using setInterval
  setInterval(updateDots, 700); // Update every 500 milliseconds
}

function generateUniqueId() {
  const timestamp = Date.now().toString(); // Current timestamp in milliseconds
  const randomPart = Math.random().toString(36).substr(2, 8); // Random alphanumeric string (8 characters)

  const event_id = `${timestamp}_${randomPart}`;
  return event_id;
}